import { ArrowDownThinIcon } from '@synoptic/ui-kit/icons/react/arrow-down-thin.tsx';
import {
  arrowContainer,
  container,
  animatedArrow,
  message,
} from './scroll-to-explore.css';

export const ScrollToExplore = () => {
  return (
    <div className={container}>
      <span className={arrowContainer}>
        <ArrowDownThinIcon className={animatedArrow} />
      </span>
      <p className={message}>scroll to explore</p>
    </div>
  );
};
