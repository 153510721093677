// This file was automatically generated and should not be edited.;
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const SvgArrowDownThin = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="m14.037 9.63-5.744 5.743-.002.002a.33.33 0 0 1-.222.092h-.002a.33.33 0 0 1-.227-.093L2.097 9.629a.32.32 0 1 1 .452-.452l5.198 5.198V.72a.32.32 0 0 1 .64 0v13.655l5.198-5.198a.32.32 0 0 1 .452 0 .32.32 0 0 1 0 .452"
    />
  </svg>
);
export const ArrowDownThinIcon = forwardRef(SvgArrowDownThin);
